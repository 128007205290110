import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="we">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="We are Raisence Committed to Raise your Digital Presence" text="" />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text"> Raisence, Your Digital Transformation Partners</h1>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Innovative Solutions" text="We specialize in crafting custom websites, mobile apps, and software that propel your brand forward" />
      <Feature title="Skilled Expertise" text="With a team of developers, designers, and strategists, we bring your vision to life with creativity and precision" />
      <Feature title="Client Satisfaction" text="Your satisfaction is our priority. We're committed to delivering top-notch results that drive your digital success" />
    </div>
  </div>
);

export default WhatGPT3;
