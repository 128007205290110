/*import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));
*/

import React from 'react';
import ReactDOM from 'react-dom';
//import ReactDom from 'react-dom';

import App from './App';

//ReactDOM.render(<App />, document.getElemntById('root'));

ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
);
  