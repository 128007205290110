import React from 'react';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="raisence">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Let&apos;s Build Something amazing with Raisence</h1>
      <p>Raise your digital presence</p>

      <div className="gpt3__header-content__input">
        <a href="#contact"><button type="button">Get Started</button></a>
      </div>

      <div className="gpt3__header-content__people" />
    </div>

    <div className="gpt3__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
