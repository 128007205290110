import React from 'react';
import './copyright.css';

const Copyright = () => (
  <div className="my_copyright">
    <p id="cr">© 2024 Raisence</p>
    <p id="em"><a href="mailto:>contact@raisence.com">contact@raisence.com</a></p>
  </div>
);

export default Copyright;