import React from 'react';

import { Footer, Possibility, WhatGPT3, Header, Copyright } from './containers';
import { CTA, Brand, Navbar, Card } from './components';

import './App.css';

const App = () => (
  <div className="App">
    <div className="gradient__bg">
      <a href="#contact">
        <div className="flying-image" />
        <div className="fixed-image" />
      </a>
      <Navbar />
      <Header />
    </div>
    <Brand />
    <WhatGPT3 />
    <Card />
    <Possibility />
    <CTA />
    <Footer />
    <Copyright />
  </div>
);

export default App;
