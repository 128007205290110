import React from 'react';
import './card.css';
import p from '../../assets/p.png';
import d from '../../assets/d.png';
import s from '../../assets/s.png';

const Card = () => (
  <section id="services">
    <div className="row">
      <h2 className="section-heading">Our Services</h2>
    </div>
    <div className="row">
      <div className="column">
        <div className="card">
          <div className="icon-wrapper">
            <i className="fa">
              <img className="ex" src={p} width="60%" />
            </i>
          </div>
          <h3>Developpment</h3>
          <p>
          Boost your online visibility with our website, app, and software development services
          </p>
        </div>
      </div>
      <div className="column">
        <div className="card">
          <div className="icon-wrapper">
            <i className="fa">
              <img className="ex" src={d} width="70%" />
            </i>
          </div>
          <h3>Multimedia Solutions</h3>
          <p>
          Elevate your brand with engaging visuals, strategic media management, and impactful ad creation 
          </p>
        </div>
      </div>
      <div className="column">
        <div className="card">
          <div className="icon-wrapper">
            <i className="fa_s">
              <img className="ex" src={s} width="100%" />
            </i>
          </div>
          <h3>Chatbot</h3>
          <p>
          Let's create chatbots to streamline inventory, support, data entry, and more for enhanced efficiency
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Card;
