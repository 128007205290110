import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="mission">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="" />
    </div>
    <div className="gpt3__possibility-content">
      <h1 className="gradient__text">Our mission is<br /> to put  possibilities <br /> beyond your imagination</h1>
      <p>Take Your Business To The Next Level</p>
    </div>
  </div>
);

export default Possibility;
